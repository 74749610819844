// Keitaro related scripts
import {globalEvent} from "./global-event";

const keitaroData = {
  keitaroActiveClass: 'to_casino_k',
}

document.addEventListener('DOMContentLoaded', () => {
  // Add keitaro events
  globalEvent('click', `.${keitaroData.keitaroActiveClass}`, keitaroHandler);
});

const keitaroHandler = (event) => {
  if (!event) {
    return;
  }

  const keitaroStatus = window.keitaro ? window.keitaro.status : null;
  const keitaroCampaignUrl = window.keitaro ? window.keitaro.campaignUrl : null;
  const customPageEnabled = window.keitaro ? window.keitaro.customPage : null;

  if (!keitaroStatus || !keitaroCampaignUrl) {
    keitaroInactiveHandler(event.target);
    return;
  }

  keitaroActiveHandler(event.target, keitaroCampaignUrl, customPageEnabled);
}

const keitaroActiveHandler = (element, campaignUrl, customPageEnabled) => {
  let ga = 'undefined';
  let gaCookie = document
      .cookie
      .split(';')
      .map(function (v) {
        return v.split('=').map(function (v) {
          return v.trim()
        })
      })
      .filter(function (v) {
        return v[0] === '_ga'
      })

  if (gaCookie[0] && gaCookie[0][1]) {
    let gaFull = gaCookie[0][1];

    if (gaFull !== undefined) {
      let gaS = gaFull.split('.')
      ga = gaS[2] + '.' + gaS[3]
    }

  } else {
    ga = '';
  }

  const casinoName = element.dataset.casino ?? 'undefined';
  const sourceUrl = window.location.href;
  const keitaroElement = element.dataset.element ?? 'undefined';
  const position = element.dataset.position ?? 'undefined';
  const gaParam = ga ? `&cid=${ga}` : '';

  const redirectUrl = `${campaignUrl}?idcasino=${casinoName}&source_url=${sourceUrl}&element=${keitaroElement}&pos=${position}${gaParam}`;
  if (customPageEnabled) {
    window.open(`/redirect?redirect_url=${encodeURIComponent(redirectUrl)}`);
    return;
  }
  window.open(redirectUrl);
}
const keitaroInactiveHandler = (element) => {
  // TODO: Ask clients do we even need this function
  const casinoName = element.dataset.casino ?? 'undefined';
  const basicRedirectUrl = `/go-to.php?url=/cl/${casinoName}/`;
  if (basicRedirectUrl && casinoName !== 'undefined') {
    window.open(basicRedirectUrl);
    return;
  }

  history.back();
}
