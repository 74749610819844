import './utils/keitaro';
import './components/header';
import './components/popups';

document.addEventListener("click", function (e) {
  const target = e.target.closest(".main-table__popover");

  if (!target) {
    document.querySelector('.main-table__popover--active')?.classList.remove('main-table__popover--active');
    return;
  }

  document.querySelector('.main-table__popover--active')?.classList.remove('main-table__popover--active');
  target.classList.add("main-table__popover--active");
});