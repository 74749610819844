document.addEventListener('DOMContentLoaded', () => {

  const messageUs = document.querySelectorAll('a[href*="#message-popup"]');
  const popupMsgUs = document.querySelector('.popup-msg-us');
  const popupClose = document.querySelectorAll('.popup-msg-us__close-js');

  if (!messageUs || !popupMsgUs) {
    return;
  }

  messageUs.forEach((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      popupMsgUs.classList.add('active');
      window.isPopupOpen = true;
    });
  });

  popupClose.forEach(function (exit) {
    exit.addEventListener('click', (e) => {
      e.preventDefault();
      popupMsgUs.classList.remove('active');
      window.isPopupOpen = false;
    });
  });

});