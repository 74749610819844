import {debounce} from "../utils/debounce";

document.addEventListener('DOMContentLoaded', () => {
  const menuToggler = document.getElementById('menu-toggler-js');
  if (menuToggler) {
    menuToggler.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active');
      const header = document.querySelector('.header-js');

      if (!header) {
        return;
      }

      document.getElementsByTagName('body')[0].classList.toggle('no-overflow');

      header.classList.toggle('active');
    })
  }

  const headerDropdowns = document.querySelectorAll('.header__dropdown-js');

  if (headerDropdowns) {
    headerDropdowns.forEach((item) => {
      item.addEventListener('click', () => {
        // only for mobile
        if (window.innerWidth >= 991) {
          return;
        }

        const subMenu = item.parentElement.querySelector('.sub-menu');
        if (!subMenu) {
          return;
        }

        item.parentElement.classList.toggle('active');
        subMenu.classList.toggle('active');
      })
    })
  }

  const menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children');
  const FREEZE_CLASS = 'freeze';

  if (menuItemsWithChildren) {
    menuItemsWithChildren.forEach((item) => {
      item.addEventListener('mouseleave', () => {
        // only for desktop
        if (window.innerWidth < 992) {
          return;
        }

        item.classList.add(FREEZE_CLASS);

        setTimeout(() => {
          item.classList.remove(FREEZE_CLASS);
        }, 500);
      })
    })
  }

  // Search
  const searchButton = document.querySelector('.header__search-js');
  const search = document.querySelector('.search-bar-js');

  if (searchButton && typeof window.searchLocalize !== 'undefined') {
    search.addEventListener('click', (e) => {
      if (e.target.classList.contains('search-bar-js')) {
        closeSearch();
      }
    })
    searchButton.addEventListener('click', openSearch);
  }

  function openSearch() {
    if (!search) {
      return;
    }

    search.classList.add('active');

    document.getElementsByTagName('body')[0].classList.add('no-overflow');
    document.addEventListener('keydown', closeSearchWithEscapeKey);
  }

  function closeSearch() {
    if (!search) {
      return;
    }

    document.getElementsByTagName('body')[0].classList.remove('no-overflow');
    search.classList.remove('active');
  }

  function closeSearchWithEscapeKey(e) {
    if (e.key === "Escape") {
      closeSearch();
      document.removeEventListener('keydown', closeSearchWithEscapeKey);
    }
  }

  // Search input handling
  const searchInput = document.getElementById('search-bar-input');

  if (searchInput) {
    searchInput.addEventListener('input', debounce((e) => {
      processSearchInput(e.target.value).then(
        // do nothing
      );
    }, 500));
  }

  const basicSearchResultsBlock = document.getElementById('basic-search-results');
  const dynamicSearchResultsBlock = document.getElementById('dynamic-search-results');
  const dynamicSearchResultsList = document.getElementById('dynamic-search-results-list');
  const searchMessage = document.getElementById('search-bar-message');

  async function processSearchInput(searchQuery) {
    const searchLocalize = window.searchLocalize;

    const ajaxUrl = searchLocalize.ajaxUrl;
    const nonce = searchLocalize.nonce;

    if (!ajaxUrl || !nonce) {
      return;
    }

    searchQuery = searchQuery.trim();

    const requestURL = new URL(ajaxUrl);
    requestURL.searchParams.append('action', 'mth_search');
    requestURL.searchParams.append('searchQuery', searchQuery);
    requestURL.searchParams.append('nonce', nonce);

    const responseJSON = await fetch(requestURL.toString());

    const response = await responseJSON.json();

    const data = response?.data;

    if (typeof data === 'undefined' || data === '') {
      outputMessage(response.message);
      return;
    }

    if (data.length === 0 && searchQuery === '') {
      showBasicSearchResults();
      return;
    }

    if (data.length === 0) {
      outputMessage(response.message);
      return;
    }

    renderSearchResults(data);
  }

  function outputMessage(message) {
    basicSearchResultsBlock.style.display = 'none';
    dynamicSearchResultsBlock.style.display = 'block';
    dynamicSearchResultsList.innerHTML = '';
    searchMessage.style.display = 'block';
    searchMessage.classList.add('not-default');
    searchMessage.textContent = message;
  }

  function showBasicSearchResults() {
    dynamicSearchResultsBlock.style.display = 'none';
    searchMessage.style.display = 'none';
    basicSearchResultsBlock.style.display = 'block';
  }

  function renderSearchResults(data) {
    searchMessage.style.display = 'none';
    basicSearchResultsBlock.style.display = 'none';
    dynamicSearchResultsBlock.style.display = 'block';
    dynamicSearchResultsList.innerHTML = '';
    let listItems = '';
    data.forEach(item => {
      listItems += `<li class="search-bar__results-item"><a class="search-bar__results-link" href="${item.link}">${item.title}</a></li>`;
    })

    dynamicSearchResultsList.insertAdjacentHTML('afterbegin', listItems);
  }

  const clearFieldButton = document.querySelector('.search-bar__field-clear-js');
  clearFieldButton.addEventListener('click', closeSearch);

  const bonusToggler = document.querySelector('.header__bonus__button-js');
  if (bonusToggler) {
    bonusToggler.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active');
      const headerBonusList = document.querySelector('.header__bonus__list-js');
      const headerBonusHeading = document.querySelector('.header__bonus__heading-js');

      if (!headerBonusList) {
        return;
      }

      headerBonusList.classList.toggle('active');
      headerBonusHeading.classList.toggle('active');
    })
  }

  const bonusTogglerIcon = document.querySelector('.header-bonus__icon-js');
  const headerBonusList = document.querySelector('.header-bonus-js');

  if (bonusTogglerIcon) {
    bonusTogglerIcon.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active');
      if (!headerBonusList) {
        return;
      }
      headerBonusList.classList.toggle('active');
      e.stopPropagation();
    });
    document.addEventListener('click', (e) => {
      const clickedElement = e.target;
      if (clickedElement !== bonusTogglerIcon && !headerBonusList.contains(clickedElement)) {
        bonusTogglerIcon.classList.remove('active');
        headerBonusList.classList.remove('active');
      }
    });
  }
});

